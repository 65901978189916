<template>
  <div class="c-form c-form--validate-account">
    <h2 class="c-margin-top-for-nav-md">Completa tus datos</h2>
    <div id="user-sign-up-onbording">
      <div class="c-input input-field">
        <input type="text" v-model="lastName" id="user-last-name" />
        <label for="user-last-name" class="active">Apellidos completo </label>
      </div>
      <div class="c-input input-field">
        <input type="text" v-model="name" id="user-last-name" />
        <label for="user-last-name" class="active">Nombres</label>
      </div>
      <div class="c-input input-field">
        <input type="text" v-model="phone" id="user-last-name" />
        <label for="user-last-name" class="active">Númmero celular</label>
      </div>
      <br />
      <div class="c-nav c-nav--floating-footer">
        <a
          href="javascript:void(0)"
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          @click="step3Complete"
          >Continuar</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepThree",
  title: "Registro - Paso 3",
  data() {
    return {
      lastName: "",
      name: "",
      phone: "",
    };
  },
  methods: {
    validateInputs() {
      let errorMsg = "";
      let isValid = true;

      if (!this.lastName) {
        errorMsg += "El campo apellido es obligatorio. <br/>";
        isValid = false;
      }

      if (!this.name) {
        errorMsg += "El campo nombre es obligatorio. <br/>";
        isValid = false;
      }

      if (!this.phone) {
        errorMsg += "El campo celular es obligatorio. <br/>";
        isValid = false;
      }

      if (this.phone && this.phone.length !== 9) {
        errorMsg += "El campo celular debe contener 9 caracteres. <br/>";
        isValid = false;
      }

      if (isValid === false) {
        this.showError(errorMsg);
      }

      return isValid;
    },
    async step3Complete() {
      const isValid = this.validateInputs();
      if (isValid === true) {
        const data = {
          lastName: this.lastName,
          name: this.name,
          phone: this.phone,
        };
        this.$store.dispatch("SET_REGISTER_DATA", data);
        this.$store.dispatch("SET_REGISTER_STEP3", true);
        this.$router.push("/signUp/step4");
      } else {
        this.$store.dispatch("SET_REGISTER_STEP3", false);
      }
    },
  },
};
</script>

<style></style>
