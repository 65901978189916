<template>
  <div class="secondAttemptOption_container">
    <OptionCard
      :optionSelected="optionSelected"
      :value="true"
      helperText="mismo día"
      :withPrice=false
      :isLoading="false"
      aditionalCostCalculated=""
      @onUpdateOption="updateSecondAttemptOption"
    >
      <template slot="secondAttemptTitle">
        <div>
          <div class="text-m-regular">Entrega en</div>
          <div class="text-m-regular">Punto Sharf cercano</div>
        </div>
      </template>
    </OptionCard>


    <OptionCard
      :optionSelected="optionSelected"
      :value="false"
      helperText="siguiente día hábil"
      :withPrice=true
      :isLoading="isLoading"
      :aditionalCostCalculated="aditionalCostCalculated"
      @onUpdateOption="updateSecondAttemptOption"
    >
      <template slot="secondAttemptTitle">
        <div>
          <div class="text-m-regular">2<sup>do</sup> intento de</div>
          <div class="text-m-regular">entrega en domicilio</div>
        </div>
      </template>
    </OptionCard>
  </div>
</template>

<script>
import OptionCard from './OptionCard.vue'

export default {
  props: ['optionSelected', 'isLoading', 'aditionalCostCalculated'],
  components: {
    OptionCard
  },
  methods: {
    updateSecondAttemptOption(value) {
      this.$emit('onUpdateOption', value)
    }
  },
}
</script>

<style lang="scss" scoped>
.secondAttemptOption_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 8px;
  padding: 24px 0;
}
</style>