<template>
  <div class="c-form c-form--validate-account">
    <h2 class="c-margin-top-for-nav-md">Identificate</h2>
    <div id="user-sign-up-onbording">
      <div class="c-collection--type-doc">
        <div
          id="svg-dni"
          class="c-box--type-doc"
          :class="[documentTypeId === 1 ? 'active' : '']"
        >
          <img src="~@/assets/img/svg/docDni.svg" alt="" />
        </div>
        <div
          id="svg-docImmigration"
          class="c-box--type-doc"
          :class="[documentTypeId === 2 ? 'active' : '']"
        >
          <img src="~@/assets/img/svg/docImmigration.svg" alt="" />
        </div>
        <div
          id="svg-other"
          class="c-box--type-doc"
          :class="[documentTypeId === 3 ? 'active' : '']"
        >
          <img src="~@/assets/img/svg/docImmigration.svg" alt="" />
        </div>
      </div>
      <h3>Tipo de documento</h3>
      <div class="c-type-doc-list c-collection collection">
        <div
          data-img="svg-dni"
          class="collection-item"
          :class="[documentTypeId === 1 ? 'active' : '']"
          @click="documentTypeId = 1"
        >
          <label class="c-radio-buttons c-rb-item-type-doc"
            ><input
              name="option-type-doc"
              type="radio"
              value="1"
              v-model="documentTypeId"
            /><span>DNI</span></label
          >
        </div>
        <div
          data-img="svg-docImmigration"
          class="collection-item"
          :class="[documentTypeId === 2 ? 'active' : '']"
          @click="documentTypeId = 2"
        >
          <label class="c-radio-buttons c-rb-item-type-doc"
            ><input
              name="option-type-doc"
              type="radio"
              value="2"
              v-model="documentTypeId"
            /><span>Carnét de extranjería</span></label
          >
        </div>
        <div
          data-img="svg-other"
          class="collection-item"
          :class="[documentTypeId === 3 ? 'active' : '']"
          @click="documentTypeId = 3"
        >
          <label class="c-radio-buttons c-rb-item-type-doc"
            ><input
              name="option-type-doc"
              type="radio"
              value="3"
              v-model="documentTypeId"
            /><span>Otro</span></label
          >
        </div>
      </div>
      <div class="c-input input-field">
        <input type="text" v-model="documentNumber" id="user-last-name" />
        <label for="user-business" class="active">Nro de Documento</label>
      </div>
      <br />
      <div>
        <a
          href="javascript:void(0)"
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          @click="step4Complete"
          >Continuar</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../../services/api";

export default {
  name: "StepFive",
  title: "Registro - Paso 4",
  data() {
    return {
      documentTypeId: 1,
      documentNumber: "",
    };
  },
  methods: {
    validateInputs() {
      let errorMsg = "";
      let isValid = true;

      if (!this.documentNumber) {
        errorMsg += "El número de documento es obligatorio. <br/>";
        isValid = false;
      } else {
        if (this.documentTypeId === 1 && this.documentNumber.length !== 8) {
          errorMsg += "El DNI debe contener 8 dígitos. <br/>";
          isValid = false;
        }

        if (this.documentTypeId === 2 && this.documentNumber.length !== 9) {
          errorMsg += "El carnét de extranjeria debe contener 9 dígitos. <br/>";
          isValid = false;
        }

        if (
          this.documentTypeId === 3 &&
          (this.documentNumber.length < 8 || this.documentNumber > 13)
        ) {
          errorMsg +=
            "Otros documentos deben contener entre 8 y 13 dígitos.<br/>";
          isValid = false;
        }
      }

      if (isValid === false) {
        this.showError(errorMsg);
      }

      return isValid;
    },
    async step4Complete() {
      const isValid = this.validateInputs();
      if (isValid === true) {
        this.showLoading();
        const data = {
          documentTypeId: this.documentTypeId,
          documentNumber: this.documentNumber,
        };

        this.$store.dispatch("SET_REGISTER_DATA", data);
        await this.sendRegisterData();
      } else {
        this.$store.dispatch("SET_REGISTER_STEP4", false);
      }
    },
    async sendRegisterData() {
      let registerCompleted = false;
      let payload = {
        name: this.registerData.name,
        lastName: this.registerData.lastName,
        email: this.registerData.email,
        phoneNumber: this.registerData.phone,
        documentTypeId: this.registerData.documentTypeId,
        documentNumber: this.registerData.documentNumber,
        password: this.registerData.password,
        confirmPassword: this.registerData.confirmPassword,
        personType: this.registerData.personType,
      };

      await this.$store
        .dispatch("POST_REGISTER", payload)
        .then((success) => {
          if (success.status === 200) {
            registerCompleted = true;
          } else {
            this.showError(success.data.message);
            this.$store.dispatch("SET_REGISTER_STEP4", false);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
          this.$store.dispatch("SET_REGISTER_STEP4", false);
        });

      if (registerCompleted === true) {
        // eslint-disable-next-line no-const-assign
        payload = {
          email: this.registerData.email,
          password: this.registerData.password,
        };
        // await this.$store.dispatch("LOGIN", payload);
        api
          .post(`auth/clients`, payload)
          .then(async (response) => {
            if (response.status === 200) {
              this.$store.commit("SET_USER", response.data.result);
            }
            this.$swal.close();
            window.location.href = process.env.VUE_APP_WEBURL;
          })
          .catch((error) => {
            this.showError(error.data.message);
          });
        this.$store.dispatch("SET_REGISTER_STEP4", true);
        this.$swal.close();
        this.$router.push("/signUp/step5");
      }
    },
  },
  computed: {
    registerData() {
      return this.$store.getters.REGISTER_DATA;
    },
  },
};
</script>

<style></style>
