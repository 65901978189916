<template>
  <div class=''>
    <div class='items-container'>
      <router-link to='/signUp'>
        <div class='back-button'>
          <img
            src='~@/assets/img/icon/icon-left-arrow.svg'
            style='cursor: pointer; height: 24px'
          />
        </div>
      </router-link>
      <div class='signup'>
        <span class='__title'>Completa tus datos y crea tu cuenta</span>
        <span class='__sub-title'>Nos ayudará a mejorar tu experiencia</span>
      </div>
    </div>
    <form class="registration-form-container" @submit="sendRegisterData">
      <div class="fields_group">
        <div class="inputs-grouped">
          <InputText
            label="Nombre(s)"
            :maxLength="150"
            placeholder="Ingresa tu(s) nombre(s)"
            v-model="name"
            missingErrorMessage="Ingresa tu(s) nombre(s)."
            :showEmptyInputError="tryToRegister && !name"
          />
          <InputText
            label="Apellidos"
            :maxLength="150"
            placeholder="Ingresa tus apellidos"
            v-model="lastName"
            missingErrorMessage="Ingresa tus apellidos."
            :showEmptyInputError="tryToRegister && !lastName"
          />
          <div>
            <InputText
              phone
              label="Teléfono"
              :maxLength="9"
              autocomplete="new-password"
              placeholder="Ingresa un teléfono"
              v-model="phone"
              missingErrorMessage="Ingresa un número de teléfono."
              :showEmptyInputError="tryToRegister && !phone"
            />
            <ErrorMessage
              v-if="tryToRegister&& phone.length !== 9"
              message="Ingrese un teléfono de 9 cifras."
            />
          </div>
        </div>
        <div class="inputs-grouped">
          <InputText
            email
            label="Correo electrónico"
            autocomplete="email"
            :maxLength="150"
            placeholder="Ingresa tu correo electrónico."
            v-model="email"
            missingErrorMessage="Este campo es obligatorio."
            :showEmptyInputError="tryToRegister && !email"
          />
          <div>
            <InputText
              password
              label="Contraseña"
              :maxLength="150"
              autocomplete="new-password"
              placeholder="Crea una contraseña"
              v-model="password"
              missingErrorMessage="Ingresa una contraseña."
              :showEmptyInputError="tryToRegister && !password"
            />
            <ErrorMessage
              v-if="tryToRegister&& password.length < 8"
              message="La contraseña debe tener mínimo 8 caracteres."
            />
          </div>
        </div>
      </div>
      <div style="padding-top: 44px">
        <CustomCheckbox
          checkboxId="account-for-bussines"
          v-model="personType"
          label="Usaré la cuenta para mi negocio"
        />
      </div>
      <div v-show="personType" style="padding-top: 28px" class="fields_group">
        <div class="inputs-grouped">
          <CustomSelect
            label="Cantidad de envíos al mes"
            placeholder="Selecciona un rango"
            :optionList="frequencieslist"
            v-model="frequency"
            errorMessage="Selecciona la cantidad de envíos que realizas al mes."
            :showEmptyInputError="tryToRegister && !frequency"
          />
          <BusinessCategorySelect
            ref="BusinessCategorySelectComponent"
            :categoriesList="categoriesList"
            v-model="subCategoriesSelected"
            label="Categoría de negocio"
            placeholder="Selecciona la(s) categoría(s)"
            errorMessage="Selecciona la(s) categoría(s) de tu negocio."
            :showEmptyInputError="tryToRegister && subCategoriesSelected.length === 0"
          />
        </div>
      </div>
      <div class="btn-and-footer-msg">
        <div class="button-create-acount">
          <ButtonDefinitive
            label="Crear cuenta"
            :isLoading="isLoading"
            @click="sendRegisterData"
          />
        </div>
        <div class="footer-msg c-form">
          <p class='text-s-regular'>
            Al seleccionar “Crear cuenta” estás aceptando los
            <a href='https://www.holascharff.com/terminos-y-condiciones' class='c-link' target='_blank'>Términos y condiciones</a> y las
            <a href='https://www.holascharff.com/politicas-de-privacidad' class='c-link' target='_blank'>Políticas de privacidad</a> de Sharf.
          </p>
          <div class='text-m-regular'>
            ¿Ya tienes una cuenta?
            <router-link to='/signIn' class='c-link'>Inicia sesión</router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { api, api2 } from "../../services/api"
import InputText from '../Form/InputText.vue'
import CustomCheckbox from "../Form/CustomCheckbox.vue"
import ButtonDefinitive from "../Button/ButtonDefinitive.vue"
import ErrorMessage from "../ErrorMessage.vue"
import CustomSelect from "../Form/CustomSelect.vue"
import BusinessCategorySelect from "../Form/BusinessCategorySelect.vue"

export default {
  name: "StepOne",
  title: "Registro - Paso 1",
  components: {
    InputText, CustomCheckbox, ButtonDefinitive, ErrorMessage, CustomSelect, BusinessCategorySelect
  },
  async asyncData({ params }) {
    console.log(params)
    return {}
  },
  data() {
    return {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      frequency: '',
      personType: true,
      tryToRegister: false,
      subCategoriesSelected: [],
      isLoading: false,
      frequencieslist: [
        { id: 'Carbon', label: '1 a 10 envíos' },
        { id: 'Bronce', label: '11 a 25 envíos' },
        { id: 'Silver', label: '26 a 50 envíos' },
        { id: 'Gold', label: '51 a más envíos' }
      ],
      categoriesList: []

    }
  },
  methods: {
    async getCategories() {
      try {
        const { data } = await api2.get(`categories`)
        this.categoriesList = data.data.map((category, i) => {
          if(category.nombre === 'Otros') {
            category.inverseParent.push({ categoriaId: 18, nombre: '', is_other_category: true, keywords: "[]" })
          }
          return {
            id: i + 1,
            category_name: category.nombre,
            is_expanded: i === 0,
            sub_categories: category.inverseParent.map(subCategory => ({
              id: subCategory.categoriaId,
              name: subCategory.nombre,
              key_words: JSON.parse(subCategory.keywords),
              is_other_category: subCategory.is_other_category
            }))
          }
        })
        return
      } catch (e) { console.log({e}) }
    },
    validateInputs() {
      let errorMsg = ''
      if(!this.name) errorMsg += 'Por favor, ingrese un nombre válido. <br/>'
      if(!this.lastName) errorMsg += 'Ingrese un apellido válido. <br/>'
      if(!this.validateEmail(this.email)) errorMsg += 'Ingrese un correo válido. <br/>'
      if(!this.phone) errorMsg += 'Por favor, ingrese un teléfono. <br/>'
      if(this.phone && this.phone.length !== 9) errorMsg += 'Por favor, ingrese un teléfono de 9 cifras. <br/>'
      if(!this.password) errorMsg += 'Ingrese una contraseña válida. <br/>'
      if(this.password.length < 8) errorMsg += 'La contraseña debe tener mínimo 8 caracteres. <br/>'
      if(this.personType && this.subCategoriesSelected.length === 0) errorMsg += 'Por favor, selecciona la(s) categoría(s) de tu negocio. <br/>'
      if(this.personType && !this.frequency) errorMsg += 'Por favor, selecciona la cantidad de envíos que realizas al mes. <br/>'
      if(errorMsg.length > 0) {
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        })
      }
      return errorMsg.length === 0
    },
    async sendRegisterData() {
      this.trackEvent('click_crear_cuenta', 'register')
      this.tryToRegister = true
      const isValid = this.validateInputs()
      if (!isValid) {
        this.trackEvent('click_crear_cuenta_failed_invalid_inputs', 'register')
        return
      }
      this.showLoading() // para que aparezca el modal de loading
      const otherCategorySelected = this.subCategoriesSelected.find(subCategory => subCategory.id === 18)
      let payload = {
        name: this.name,
        lastname: this.lastName,
        phoneNumber: this.phone,
        email: this.email,
        password: this.password,
        confirmPassword: this.password,
        personType: this.personType ? "EMP" : "PER",
        frequency: this.frequency,
        subCategoriesSelected: this.subCategoriesSelected.map(subCategory => subCategory.id),
        otherCategoryDescription: otherCategorySelected?.name || '',
        accountManager: this.$route.query.accountManager,
        source: this.$route.query.source || 'Marketing'
      }
      console.log(payload)
      await this.$store
        .dispatch("POST_REGISTER", payload)
        .then(async (success) => {
          if (success.status === 200) {
            this.trackEvent('registro_exitoso_email', 'register')
            fbq('track', 'CompleteRegistration')
            // @ts-ignore
            payload = {
              email: this.email,
              password: this.password,
            }
            // await this.$store.dispatch("LOGIN", payload)
            api
              .post(`auth/clients`, payload)
              .then(async (response) => {
                if (response.status === 200) {
                  this.$store.commit("SET_USER", response.data.result)
                  this.trackEvent('login_after_signup_email_success', 'login')
                }
                this.$swal.close()
                // @ts-ignore
                window.location.href = process.env.VUE_APP_WEBURL
              })
              .catch((error) => {
                this.showError(error.data.message)
                this.trackEvent('login_after_signup_email_error', 'login')
              })
          } else {
            this.showError(success.data.message)
            this.trackEvent('login_after_signup_email_error', 'login')
          }
        })
        .catch((error) => {
          this.trackEvent('registro_fallido_email', 'register')
          const msg = error?.data?.message || "Ocurrio un error."
          this.showError(msg)
        })
    },
    getEmail() {
      this.email = localStorage.getItem('registerEmail')
    }
  },
  created () {
    this.getCategories()
    this.getEmail()
  },
  watch: {
    phone(newValue) {
      const regex = /^\+?\d{0,3}\s?\(?\d{0,4}\)?\s?\d{0,4}\s?\d{0,4}$/
      if (!regex.test(newValue)) {
        this.phone = newValue.replace(/[^0-9+\s()]/g, '')
      }
    },
    personType(newValue) {
      if(!newValue) {
        this.subCategoriesSelected = []
        this.frequency = ''
        this.$refs.BusinessCategorySelectComponent.resetSelectedCategories()
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.items-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  .back-button {
    height: 64px;
    display: flex;
    align-items: center;
  }
  .signup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    font-style: normal;
    padding: 16px 0 48px 0px;
    .__title {
      color: #1A1A1A;
      font-weight: 700;
      line-height: 40px;
      font-size: 32px;
    }
    .__sub-title {
      color: #737373;
      font-weight: 400;
      line-height: 28px;
      font-size: 18px;
    }
  }
}


.registration-form-container {
  width: 100%;
}
.fields_group {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .inputs-grouped {
    display: grid;
    gap: 16px;
    align-items: start;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  }
}

.btn-and-footer-msg {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 48px 0;
  .button-create-acount {
    width: fit-content;
    max-width: 100%;
  }

  .footer-msg {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 600px) {
  .button-create-acount {
    width: 100% !important;
  }
}
</style>