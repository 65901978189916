<template>
  <div class="so_container" :class="{ 'so-selected' : optionSelected == value }" @click="updateActiveOption">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['optionSelected', 'value'],
  methods: {
    updateActiveOption() {
      this.$emit('onUpdateOption', this.value)
    }
  },
}
</script>

<style lang="scss" scoped>
.so_container {
  border-radius: 12px;
  border: 1px solid #D0D0D0;
  background: #FFF;
  transition: box-shadow .3s;
  cursor: pointer;
  padding: 16px;

  &.so-selected {
    border-width: 2px;
    border-color: #121212;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }

  &:hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

</style>