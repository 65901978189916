<template>
  <div class="header-tracking-container">
    <img
      v-if="showBack"
      src="../../assets/flecha.svg"
      alt="flecha"
      style="width: 30px; position: absolute; right: 20px; cursor: pointer"
      @click="$emit('back')"
    />
    <div class="header-tracking-title">
      <span style="font-weight: bolder; font-size: 2.5rem">Tu envío está </span
      ><span style="font-size: 2.5rem; text-decoration: underline"
        >en ruta.</span
      >
    </div>
    <div class="header-detail-section">
      <span style="font-weight: bold; font-size: 1.2rem"
        >Información de tu envío</span
      >
      <div class="details">
        <div style="font-weight: 600">N° de envío:</div>
        <div class="resalted">{{ guide.guideNumber }}</div>
        <div style="font-weight: 600">Tipo de servicio:</div>
        <div class="resalted">{{ guide.typeService }}</div>
        <!-- <div style="font-weight: 600">Cantidad:</div>
        <div class="resalted">******************</div> -->
        <div style="font-weight: 600">Destino:</div>
        <div class="resalted">{{ guide.destinyDistrict }}</div>
        <div style="font-weight: 600">Recibe:</div>
        <div class="resalted">{{ guide.destinatary }}</div>
        <!-- <div style="font-weight: 600">Cel. destinatario:</div>
        <div class="resalted">******************</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingResultHeader",
  props: {
    guide: null,
    showBack: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-tracking-container {
  margin-top: 20px;
  background-color: white;
  padding: 2rem;
  .header-detail-section {
    margin-top: 20px;
    .details {
      display: grid;
      grid-template-columns: 120px 1fr;
      row-gap: 1em;
      margin-top: 10px;
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 120px 1fr);
      }
      .resalted {
        background-color: #ededed;
        color: #858585;
        font-size: 1.1rem;
        border-radius: 20px;
        padding: 0px 15px;
        @media (min-width: 768px) {
          margin-right: 30px;
        }
      }
    }
  }
}
</style>
