<template>
  <SelectableOption :optionSelected="optionSelected" :value="value" @onUpdateOption="updateSecondAttemptOption">
    <div class="secondAttemptOption_body">
      <slot name="secondAttemptTitle"></slot>
      <div style="display: flex; justify-content: space-between;">
        <div class="text-s-regular" style="color: #737373; padding-top: 4px; min-width: 120px">{{ helperText }}</div>
        <div v-if="withPrice" class="text-m-bold" style="text-align: right">
          <RingLoader :size="2" hexColor="A0A0A0" v-if="isLoading"/>
          <span v-else>{{ !aditionalCostCalculated ? 'Pago' : `+ S/ ${aditionalCostCalculated}` }}</span>
        </div>
        <div v-else class="text-m-bold" style="text-align: right">+ 0</div>
      </div>
    </div>
  </SelectableOption>
</template>

<script>
import SelectableOption from '../SelectableOption.vue'
import RingLoader from '../../Loaders/RingLoader.vue';

export default {
  components: {
    SelectableOption,
    RingLoader
  },
  props: ['optionSelected', 'value', 'helperText', 'withPrice', 'isLoading', 'aditionalCostCalculated'],
  methods: {
    updateSecondAttemptOption() {
      this.$emit('onUpdateOption', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.secondAttemptOption_body {
  display: flex;
  flex-direction: column;
}
</style>