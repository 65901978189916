<template>
  <div class="c-form c-form--validate-account">
    <h2>Validar cuenta</h2>
    <div class="c-text-left">
      Enviamos un correo electrónico a tu bandeja e ingresa el código que te
      llegó.
    </div>
    <div id="user-login">
      <div class="c-form--validate-account__container">
        <div class="c-input--code">
          <input
            type="number"
            placeholder="0"
            minlength="1"
            v-model="digit1"
            oninput="if(value.length>1)value=value.slice(0,1)"
          />
        </div>
        <div class="c-input--code">
          <input
            type="number"
            placeholder="0"
            minlength="1"
            v-model="digit2"
            oninput="if(value.length>1)value=value.slice(0,1)"
          />
        </div>
        <div class="c-input--code">
          <input
            type="number"
            placeholder="0"
            minlength="1"
            v-model="digit3"
            oninput="if(value.length>1)value=value.slice(0,1)"
          />
        </div>
        <div class="c-input--code">
          <input
            type="number"
            placeholder="0"
            minlength="1"
            v-model="digit4"
            oninput="if(value.length>1)value=value.slice(0,1)"
          />
        </div>
        <div class="c-input--code">
          <input
            type="number"
            placeholder="0"
            minlength="1"
            v-model="digit5"
            oninput="if(value.length>1)value=value.slice(0,1)"
          />
        </div>
      </div>
      <div class="c-switch switch">
        <label class="row"
          ><div class="col s2">
            <input type="checkbox" v-model="tyc" /><span class="lever"></span>
          </div>
          <div class="col s10">
            <div class="c-switch__text">
              Acepto los
              <a href="javascript:void(0)" class="c-link"
                >Términos y Condiciones </a
              >y autorizo el uso de mis datos de acuerdo a la
              <a href="javascript:void(0)" class="c-link"
                >Declaración de Privacidad.</a
              >
            </div>
          </div></label
        >
      </div>
      <div class="c-nav c-nav--floating-footer">
        <a
          href="javascript:void(0)"
          class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
          @click="step2Complete"
          >Continuar</a
        >
        <button
          class="c-button c-button--primary c-button--width-fuid c-button--flat c-button--mobile-small waves-effect"
          @click="reSendCode"
        >
          Enviar otro código
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepTwo",
  title: "Registro - Paso 2",
  data() {
    return {
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      tyc: false,
    };
  },
  methods: {
    async reSendCode() {
      this.showLoading();
      await this.$store
        .dispatch("POST_SEND_CODE", this.email)
        .then((success) => {
          if (success.status === 200) {
            this.showOk("Se ha enviado un codigo de verificación a su correo.");
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    validateInputs() {
      let errorMsg = "";
      let isValid = true;

      if (
        !this.digit1 ||
        !this.digit2 ||
        !this.digit3 ||
        !this.digit4 ||
        !this.digit5
      ) {
        errorMsg += "Por favor, ingrese todos los dígitos. <br/>";
        isValid = false;
      }

      if (this.tyc === false) {
        errorMsg += "Debe aceptar los términos y condiciones para continuar.";
        isValid = false;
      }

      if (isValid === false) {
        this.showError(errorMsg);
      }

      return isValid;
    },
    async step2Complete() {
      const isValid = this.validateInputs();
      if (isValid === true) {
        const code =
          this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5;
        this.showLoading();
        const data = {
          email: this.email,
          code,
        };
        await this.$store
          .dispatch("POST_VERIFY_CODE", data)
          .then((success) => {
            if (success.status === 200) {
              this.$swal.close();
              this.$store.dispatch("SET_REGISTER_STEP2", true);
              this.$router.push("/signUp/step3");
            } else {
              this.showError(success.data.message);
              this.$store.dispatch("SET_REGISTER_STEP2", false);
            }
          })
          .catch((error) => {
            const msg = error?.data?.message || "Ocurrio un error.";
            this.$store.dispatch("SET_REGISTER_STEP2", false);
            this.showError(msg);
          });
      } else {
        this.$store.dispatch("SET_REGISTER_STEP2", false);
      }
    },
  },
  computed: {
    email() {
      return this.$store.getters.REGISTER_EMAIL;
    },
  },
};
</script>

<style></style>
