<template>
    <div class="c-nav c-nav--floating-footer-sticky-pending-payment padding-payment-footer">
      <ButtonDefinitive
        label="Ver recibo"
        @click="sendToPayU"
      />
    </div>
  </template>
  
  <script>
  import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

  export default {
    name: "OrderPendingPaymentFooter",
    components: { ButtonDefinitive },
    methods: {
      sendToPayU() {
        this.trackEvent('click_ver_recibo', 'orden');
        window.open(this.payment.extraParameters.URL_PAYMENT_RECEIPT_HTML);
      }
    },
    computed: {
    lstDestination() {
      return this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
    },
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    contactOrigin() {
      return this.$store.getters.SCHARFFPOINT_ORIGIN_CONTACT;
    },
    productValue() {
      return this.$store.getters.SCHARFFPOINT_PRODUCT_VALUE;
    },
    type() {
      return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
    },
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
  },
  };
  </script>
  
  <style>
    .padding-payment-footer {
      position: relative;
      padding: 32px 16px !important;
      bottom: 105px !important;
      background: #FFF;
      box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
    }
    @media (min-width: 768px) {
    .padding-payment{
        bottom: 25px !important;
    }
    }
    @media (min-width: 600px) {
    .c-button--width-fuid-pending-payment{
        width: fit-content !important;
    }
    .c-nav--floating-footer-sticky-pending-payment{
        justify-content: start !important;
    }
    .padding-payment {
        padding: 48px 48px !important;
    }
    }

    @media (min-width: 900px) {
    .padding-payment {
        padding: 48px 20px !important;
    }
    }
    </style>