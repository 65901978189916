<template>
  <div class="tracking-search-container">
    <span style="font-size: 1.5rem">Tracking</span>
    <span style="font-weight: bolder; font-size: 2.5rem"
      >conoce por dónde va tu envío</span
    >
    <div class="search-guide-container">
      <span style="margin-bottom: 10px; font-size: 1.2rem"
        >Ingresa el número de guía</span
      >
      <input type="text" class="input-search" v-model="guideNumber" />
      <SButton extraClass="search-button" @click="search">localizar</SButton>
    </div>
  </div>
</template>

<script>
import SButton from "../../library/SButton.vue";

export default {
  name: "TrackingSearch",
  components: { SButton },
  data() {
    return {
      guideNumber: "",
    };
  },
  methods: {
    search() {
      if (this.guideNumber !== "") {
        this.$emit("search", this.guideNumber);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tracking-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  .search-guide-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    max-width: 90vw;
    width: 500px;
    padding: 1.5rem;

    .input-search {
      border: solid 1px;
      border-radius: 22px;
      margin-bottom: 30px;
    }

    .search-button {
      border-radius: 22px;
      width: fit-content;
      align-self: center;
    }
  }
}
</style>
