<template>
  <div class="c-form c-form--validate-account">
    <h2 class="c-margin-top-for-nav-md">Agrega una tarjeta</h2>
    <form id="user-sign-up-onbording">
      <div class="c-box--credit-card">
        <div class="row">
          <div class="col s6"><h4>Banco</h4></div>
          <div class="col s6 c-text-right">
            <div class="c-box--credit-card__chip"></div>
          </div>
        </div>
        <div v-show="false">
          <input
            type="text"
            size="50"
            data-culqi="card[email]"
            id="card[email]"
            v-model="newCard.email"
          />
        </div>
        <div class="row">
          <div class="col s12">
            <div class="c-input input-field c-input--white">
              <input
                type="text"
                v-model="newCard.number"
                data-culqi="card[number]"
                id="card[number]"
                @keypress="onlyNumbers"
              />
              <label for="card[number]" class="active">Número de tarjeta</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s4">
            <div class="c-input input-field c-input--white">
              <input
                type="text"
                v-model="newCard.year"
                data-culqi="card[exp_year]"
                id="card[exp_year]"
                @keypress="onlyNumbers"
              />
              <label for="card[exp_year]" class="active">Año</label>
            </div>
          </div>
          <div class="col s4">
            <div class="c-input input-field c-input--white">
              <input
                type="text"
                v-model="newCard.month"
                @change="padMonthZero"
                data-culqi="card[exp_month]"
                id="card[exp_month]"
                @keypress="onlyNumbers"
              />
              <label for="card[exp_month]" class="active">Mes</label>
            </div>
          </div>
          <div class="col s4">
            <div class="c-input input-field c-input--white">
              <input
                type="text"
                v-model="newCard.cvv"
                data-culqi="card[cvv]"
                id="card[cvv]"
                @keypress="onlyNumbers"
              />
              <label for="card[cvv]" class="active">CVV</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s6">
            <div class="c-input input-field c-input--white">
              <input type="text" v-model="newCard.name" id="card-name" />
              <label for="card-name" class="active">Titular</label>
            </div>
          </div>
          <div class="col s6">
            <div class="c-input input-field c-input--white">
              <input type="text" v-model="newCard.alias" id="card-alias" />
              <label for="card-alias" class="active">Alias</label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <div
        class="c-notification c-notification c-notification--alert-notification"
      >
        <h5>Importante:</h5>
        <div class="text">
          Para Tarjeta de Crédito/Débito puedes utilizar Visa, MarterCard y
          American Express, si utlizas tarjeta MasterCard Ripley contáctate con
          tu banco previamente y valida si cuentas activada la opción de pagos
          online.
        </div>
      </div>
    </div>
    <div class="c-nav c-nav--floating-footer">
      <a
        href="javascript:void(0)"
        @click="saveNewCard"
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
        >Finalizar</a
      ><a
        href="javascript:void(0)"
        @click="redirectToHome"
        class="c-button c-button--width-fuid c-button--flat c-button--mobile-small waves-effect"
        >Omitir y finalizar</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StepFive",
  title: "Registro - Paso 5",
  data() {
    return {
      newCard: {
        alias: null,
        name: null,
        number: null,
        year: null,
        month: null,
        cvv: null,
        email: null,
      },
    };
  },
  mounted() {
    this.$nextTick(async () => {
      this.newCard.email = this.user.email;
    });
  },
  methods: {
    redirectToHome() {
      window.location.href = process.env.VUE_APP_WEBURL;
    },
    setDefaultNewCard() {
      this.newCard = {
        alias: null,
        name: null,
        number: null,
        year: null,
        month: null,
        cvv: null,
        email: null,
      };
    },
    padMonthZero(e) {
      const element = e.target;
      const { value } = element;
      if (value === "") return;
      this.newCard.month = value.padStart(2, "0");
    },
    validateNewCard() {
      if (!this.newCard.alias || this.newCard.alias.length < 4) {
        this.showError(
          "Ingrese un alias válido, recuerde que debe ser mayor a 4 caracteres."
        );
        return false;
      }

      if (!this.newCard.name || this.newCard.name.length < 4) {
        this.showError(
          "Ingrese un nombre de titular válido, recuerde que debe ser mayor a 4 caracteres."
        );
        return false;
      }

      if (
        !this.newCard.number ||
        !(this.newCard.number.length >= 14 && this.newCard.number.length <= 16)
      ) {
        this.showError(
          "Ingrese un número de tarjeta válido, recuerde que debe tener entre 14 y 16 caracteres."
        );
        return false;
      }

      if (!this.newCard.year || this.newCard.year.length !== 4) {
        this.showError(
          "Ingrese año válido, recuerde que debe tener 4 caracteres."
        );
        return false;
      }

      if (!this.newCard.month || this.newCard.month.length !== 2) {
        this.showError(
          "Ingrese mes válido, recuerde que debe tener 2 caracteres."
        );
        return false;
      }

      if (
        !this.newCard.cvv ||
        !(this.newCard.cvv.length >= 3 && this.newCard.cvv.length <= 4)
      ) {
        this.showError(
          "Ingrese mes válido, recuerde que debe tener entre 3 y 4 caracteres."
        );
        return false;
      }

      return true;
    },
    async completeAddCard() {
      let successPost = false;
      const model = {
        alias: this.newCard.alias,
        owner: this.newCard.name,
        card_number: this.newCard.number,
        ccv: this.newCard.cvv,
        expiration_month: this.newCard.month,
        expiration_year: this.newCard.year,
        isDefaultCard: false,
        token_id: window.Culqi.token.id,
        customer_id: this.user.culqiClientId,
        email: this.newCard.email,
      };
      await this.$store
        .dispatch("POST_CARD", model)
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            successPost = true;
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });

      if (successPost === true) {
        this.setDefaultNewCard();
        window.location.href = process.env.VUE_APP_WEBURL;
      }
    },
    async secureTokenCreation() {
      if (window.Culqi.token && window.Culqi.token.object === "token") {
        await this.completeAddCard();
      } else {
        this.$swal.close();

        this.showError(
          window.Culqi.error.user_message
            ? window.Culqi.error.user_message
            : window.Culqi.token.user_message
        );
      }
    },
    async timeout(ms) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async saveNewCard() {
      const isValid = this.validateNewCard();
      if (isValid === true) {
        window.Culqi.createToken();
        this.showLoading();
        await this.timeout(3000);
        await this.secureTokenCreation();
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style></style>
