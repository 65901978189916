<template>
  <div style='display: flex; flex-direction: column; align-items: center'>
    <div class='scharff-logo'><ScharffLogo /></div>
    <div style='padding: 48px 0; display: flex; flex-direction: column; gap: 8px; align-items: center'>
      <span class='text-4xl-bold'>Crea tu cuenta</span>
      <span class='text-m-regular' style='color: #737373'>y empieza a realizar envíos</span>
    </div>
    <form @submit.prevent='handleSubmit' class='c-form' style='width: 100%; max-width: 408px; display: flex; flex-direction: column; gap: 16px; padding-bottom: 48px'>

      <div>
        <InputText
          email
          label="Correo electrónico"
          autocomplete="email"
          placeholder="Ingresa tu correo electrónico."
          v-model="email"
          missingErrorMessage="Ingresa tu correo electrónico."
          :showEmptyInputError="tryToSubmit && !email"
        />
        <ErrorMessage
          message='El correo que ingresaste ya está asociado a una cuenta. Ingresa otro correo electrónico.'
          v-if="showEmailAlreadyUsedMsg"
        />
      </div>

      <ButtonDefinitive
        :isLoading="isLoading"
        label='Continuar con mi correo'
        buttonType='submit'
      >
        <template v-slot:icon><img src='~@/assets/img/icon/icon-message.svg'></template>
      </ButtonDefinitive>

      <!-- Separator -->
      <div style='width: 100%; padding: 8px 0; justify-content: center; align-items: center; display: inline-flex'>
        <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
        <div style='padding-left: 8px; padding-right: 8px; flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
          <div style='justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
            <div style='color: #737373; font-size: 14px; font-weight: 400; line-height: 20px; word-wrap: break-word'>o</div>
          </div>
        </div>
        <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
      </div>

      <GoogleButton
        :queryParams="$route.fullPath"
        :isRegistrationPage="true"
      />

      <AppleButton
        :queryParams="$route.fullPath"
        :isRegistrationPage="true"
      />

      <!-- Facebook -->
      <ButtonDefinitive
        variant='ghost' label='Continuar con Facebook'
        @click='logInWithFacebook'
      >
        <template v-slot:icon><img src='~@/assets/img/icon/icon-facebook_v2.svg'></template>
      </ButtonDefinitive>

      <p class='text-s-regular' style='text-align: center'>
        Al seleccionar “Continuar con Google”, “Continuar con Apple” o “Continuar con Facebook” aceptas nuestros
        <a href='https://www.holascharff.com/terminos-y-condiciones' class='c-link' target='_blank'>Términos y condiciones</a> y
        <a href='https://www.holascharff.com/politicas-de-privacidad' class='c-link' target='_blank'>Políticas de privacidad</a>.
      </p>
      <div class='text-m-regular' style='text-align: center'>
        ¿Ya tienes una cuenta?
        <router-link to='/signIn' class='c-link'>Inicia sesión</router-link>
      </div>

    </form>
  </div>
</template>

<script>
import ScharffLogo from '../General/ScharffLogo.vue'
import InputText from '../Form/InputText.vue'
import ButtonDefinitive from '../Button/ButtonDefinitive.vue'
import GoogleButton from '../Login/GoogleButton.vue'
import AppleButton from '../Login/AppleButton.vue'
import utils from '../../commons/mixins/utils'
import ErrorMessage from '../ErrorMessage.vue'
import { emailInUseValidator } from '../../services/client'

export default {
  name: 'emailSocial',
  title: 'Registro',
  components: { ScharffLogo, InputText, ButtonDefinitive, GoogleButton, ErrorMessage, AppleButton },
  data() {
    return {
      email: '',
      tryToSubmit: false,
      isLoading: false,
      showEmailAlreadyUsedMsg: false,
    }
  },
  methods: {
    getQueryParameters () {
      const currentPathWithQuery = this.$route.fullPath
      const queryIndex = currentPathWithQuery.indexOf('?')
      return queryIndex !== -1 ? currentPathWithQuery.slice(queryIndex + 1) : ''
    },
    async handleSubmit() {
      this.tryToSubmit = true
      if(!this.email || !utils.methods.validateEmail(this.email)) {
        this.trackEvent('click_register_with_email_failed_invalid_inputs', 'register')
        return
      }

      this.isLoading = true
      try {
        const isEmailAlreadyUsed = await emailInUseValidator(this.email)
        if (isEmailAlreadyUsed) {
          this.trackEvent('click_register_with_email_failed_email_already_used', 'register')
          return this.showEmailAlreadyUsedMsg = true
        }
      }
      catch (e) { console.log(e) }
      finally { this.isLoading = false }


      this.trackEvent('click_register_with_email', 'register')

      localStorage.setItem('registerEmail', this.email)

      const queryString = this.getQueryParameters()
      this.$router.push('/signUp/additional-data' + (queryString ? `?${queryString}` : ''))

    },
    async logInWithFacebook() {
      this.trackEvent('click-facebook-btn', 'register')
      const $this = this
      $this.showLoading()
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const { accessToken } = response.authResponse
            const queryString = this.getQueryParameters()
            $this.$store.dispatch('FB_LOGIN', {accessToken, queryString})
              .then((success) => {
                if (success.status === 200) {
                  const result = success.data.result;

                  $this.$store.dispatch('USER_SET_USER', success.data.result)

                  if(result.isNewClient){
                    this.trackEvent('login_with_facebook_after_register_success', 'login')
                    fbq('track', 'CompleteRegistration')
                  } else {
                    this.trackEvent('login_with_facebook_success', 'login')
                  }

                  window.location.href = process.env.VUE_APP_WEBURL
                } else {
                  $this.showError(success.data.message)
                  this.trackEvent('login_with_facebook_failed', 'login')
                }
              })
              .catch((error) => {
                const msg = error?.data?.message || 'Ocurrio un error.'
                $this.showError(msg)
                this.trackEvent('login_with_facebook_failed', 'login')
              })
          } else {
            $this.showError('Inicio de Sesion con Facebook Cancelado.')
            this.trackEvent('login_with_facebook_failed', 'login')
          }
        },
        { scope: 'email' }
      )
      return false
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_KEY,
          cookie: true,
          xfbml: true,
          version: 'v6.0'
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      const js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
  },
  watch: {
    email(nv) {
      this.showEmailAlreadyUsedMsg = false
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
      await this.initFacebook()
      this.closeSidebar()
    })
    this.email = localStorage.getItem('registerEmail') || ''
    localStorage.removeItem('registerEmail')
  },
}
</script>

<style lang='scss' scoped>

.scharff-logo {
  display: flex; justify-content: center;
  svg { width: 112px; padding-top: 32px }
}

</style>