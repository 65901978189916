<template>
  <div class="tracking-detail-container">
    <span style="font-size: 1.2rem; font-weight: bold">Progreso del envío</span>
    <div class="tracking-details">
      <div
        class="group-container"
        v-for="(item, index) in guide.activity"
        :key="index"
      >
        <div class="point-container">
          <div :class="`point-${item.type}`">
            {{ index + 1 }}
          </div>
        </div>
        <div>
          <div class="status-container">
            <img src="../../assets/box.svg" alt="box" style="width: 48px" />
            <span style="font-size: 1.2rem; font-weight: bold">{{
              item.title
            }}</span>
            <span style="color: #8d8d8d; margin-top: 10px">{{
              item.hour
            }}</span>
            <span
              style="
                color: #8d8d8d;
                margin-top: 5px;
                max-width: 180px;
                text-align: justify;
                font-size: 0.8rem;
              "
              >{{ item.detail }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingDetail",
  props: {
    guide: null,
  },
};
</script>

<style lang="scss" scoped>
.tracking-detail-container {
  background-color: white;
  margin: 2rem;
  padding: 2rem;
  .tracking-details {
    display: grid;
    grid-template-columns: 1fr; /* 1 columna */
    grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
    gap: 30px;
    margin-top: 30px;
    @media (min-width: 768px) {
      grid-template-columns: repeat(
        auto-fit,
        minmax(150px, 1fr)
      ); /* 1 fila con una cantidad de filas de forma dinámica */
      grid-template-rows: 1fr;
    }
    .group-container {
      display: flex;
      gap: 1rem;
      @media (min-width: 768px) {
        flex-direction: column;
      }
      .status-container {
        display: flex;
        flex-direction: column;
      }
      .point-container {
        position: relative;

        .point-success {
          background-color: #00c987;
          color: white;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: "";
            width: 2px;
            height: calc(100%);
            background-color: #00c987;
            position: absolute;
            top: 30px;
            display: flex;
            @media (min-width: 768px) {
              content: "";
              width: calc(100%);
              height: 2px;
              background-color: green;
              position: absolute;
              left: 30px;
              top: 15px;
              display: flex;
            }
          }
        }
        .point-error {
          background-color: red;
          color: white;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: "";
            width: 2px;
            height: calc(100% - 20px);
            background-color: red;
            position: absolute;
            top: 30px;
            display: flex;
            @media (min-width: 768px) {
              content: "";
              width: calc(100% - 20px);
              height: 2px;
              background-color: red;
              position: absolute;
              left: 30px;
              top: 15px;
              display: flex;
            }
          }
        }
      }
      &:last-child {
        .point-container {
          .point-success {
            &::before {
              content: "";
              background-color: transparent !important;
            }
          }
          .point-error {
            &::before {
              content: "";
              background-color: transparent !important;
            }
          }
        }
      }
    }
    // .group-container:has(.point-error) + div {
    //   background-color: blue !important;
    // }
  }
}
</style>
