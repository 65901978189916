<template>
  <article class='fix-padding-pending c-scrollbar' ref='nav' @scroll='handleScroll'>
    <div style='position: sticky; top: 0; z-index: 2;'>
      <div class='pending-nav-header'>
        <div class='home-button-nav' @click='redirectHome'>
          <svg width='24px' height='24px' stroke-width='1.5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='#68002B'>
            <path d='M15 6l-6 6 6 6' stroke='#68002B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
          <div class='text-m' style='color: #68002B'>Inicio</div>
        </div>
        <div class='pending-nav-header-title'>
          <div :style='titleStyles' class='text-m loading bold color-brown'>Esperando pago</div>
        </div>
      </div>
      <div :style='titleStyles' style='text-align: center;' class='box-title-pending sub-title-waiting-for-pay'>El pago puede tardar unos minutos en procesar</div>
    </div>
    <div style='margin-top: -42px;' class='pending-content-body'>
      <div class='box-title-pending'>
        <svg width='24px' height='24px' stroke-width='2.5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' class='title-icon' color='#68002B'>
          <path d='M12 6v6h6' stroke='#68002B' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' />
          <path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z' stroke='#68002B' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
        <div class='title-pending loading'>Esperando pago</div>
      </div>
      <div style='padding-bottom: 20px' class='box-title-pending sub-title-waiting-for-pay'>El pago puede tardar unos minutos en procesar</div>
      <div class='content-pending'>
        <div class='content-pending-son'>
          <div class='description-payment'>Paga con el código de pago (CIP) y podrás acceder a las guías de tus paquetes en la sección Mis envíos.</div>
          <div class='box-payment-content'>
            <div class='card-payment-content-no-gap'>
              <div class='cip-content'>
                <div class='text-s'>Código de pago (CIP)</div>
                <div class='text-2xl'>{{ payment && payment.extraParameters ? payment.extraParameters.BAR_CODE : '' }}
                </div>
              </div>
              <div class='button-copy' @click='copyText'>
                <svg width='24px' height='24px' class='copy-icon' stroke-width='2.5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='#FF565D'>
                  <path d='M19.4 20H9.6a.6.6 0 01-.6-.6V9.6a.6.6 0 01.6-.6h9.8a.6.6 0 01.6.6v9.8a.6.6 0 01-.6.6z' stroke='#FF565D' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round' />
                  <path d='M15 9V4.6a.6.6 0 00-.6-.6H4.6a.6.6 0 00-.6.6v9.8a.6.6 0 00.6.6H9' stroke='#FF565D' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round' />
                </svg>
                <div class='text-m color-primary'>Copiar</div>
              </div>
            </div>
            <div class='card-payment-content'>
              <div class='text-s'>Monto a pagar</div>
              <div class='text-2xl'>S/ {{ order.fare.toFixed(2) }}</div>
            </div>
            <div class='card-payment-content'>
              <div class='text-s'>Vencimiento</div>
              <div class='text-m'>
                {{ (new Date(payment.extraParameters.EXPIRATION_DATE)).toLocaleString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}<br>
                {{ (new Date(payment.extraParameters.EXPIRATION_DATE)).toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit', hour12: true }) }}
              </div>
            </div>
          </div>
          <div class='box-info'>
            <div class='text-l'>Información de pago</div>
            <div class='card-info-order-details'>
              <div class='info-group'>
                <div class='text-s'>Pagador</div>
                <div class='text-m'>{{ order.voucher.name }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Método de pago</div>
                <div class='text-m'>{{ order.card.cardBrand }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Código de pago (CIP)</div>
                <div class='text-2xl'>{{ payment && payment.extraParameters ? payment.extraParameters.BAR_CODE : '' }}
                </div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Total a pagar</div>
                <div class='text-m'>S/ {{ order.fare.toFixed(2) }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Vencimiento</div>
                <div class='text-m'>
                  {{ (new Date(payment.extraParameters.EXPIRATION_DATE)).toLocaleString('es', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}<br>
                  {{ (new Date(payment.extraParameters.EXPIRATION_DATE)).toLocaleTimeString('es', { hour: 'numeric', minute: 'numeric', hour12: true }) }}</div>
              </div>
            </div>
          </div>
          <div class='box-info'>
            <div class='text-l'>Recojo</div>
            <div class='card-info-order-details'>
              <div class='info-group'>
                <div class='text-s'>Dirección de recojo</div>
                <div class='text-m'>{{ origin.description === '' ? `${origin.scharffPoint.Name} - ${origin.scharffPoint.Address}` : origin.description }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Remitente</div>
                <div class='text-m'>{{ contactOrigin.name }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Número de celular</div>
                <div class='text-m'>{{ contactOrigin.phone }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Correo electrónico</div>
                <div class='text-m'>{{ contactOrigin.email }}</div>
              </div>
            </div>
          </div>
          <div class='box-info'>
            <div class='text-l'>Paquetes</div>
            <div v-for='objDest of lstDestination' class='card-info-order-details' :key='objDest.description'>
              <div class='text-m bold'>{{ objDest.description }}</div>
              <div class='info-group'>
                <div class='text-s'>Dirección de entrega</div>
                <div class='text-m'>
                  {{ !objDest.destinationAddress.description ?
                    (objDest.destinationAddress.puntoScharffId.Name ?
                      `${objDest.destinationAddress.puntoScharffId.Name} - ${objDest.destinationAddress.puntoScharffId.Address}` :
                      objDest.destinationAddress.puntoScharffId.address
                    ) :
                    objDest.destinationAddress.description }}
                </div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Destinatario</div>
                <div class='text-m'>{{ objDest.destinationAddress.contact.name }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Número de celular</div>
                <div class='text-m'>{{ objDest.destinationAddress.contact.phone }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Correo electrónico</div>
                <div class='text-m'>{{ objDest.destinationAddress.contact.email }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Peso</div>
                <div class='text-m'>{{ Number(objDest.packageSize.weight).toFixed(2) }} kg</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Largo</div>
                <div class='text-m'>{{ Number(objDest.packageSize.lengths).toFixed(2) }} cm</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Alto</div>
                <div class='text-m'>{{ Number(objDest.packageSize.height).toFixed(2) }} cm</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Ancho</div>
                <div class='text-m'>{{ Number(objDest.packageSize.width).toFixed(2) }} cm</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Valor del producto</div>
                <div class='text-m'>S/ {{ Number(objDest.productValue).toFixed(2) }}</div>
              </div>
              <div class='info-group'>
                <div class='text-s'>Costo de envío</div>
                <div class='text-m'>S/ {{ objDest.discountFare ? Number(objDest.discountFare).toFixed(2) : objDest.fare.toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { getOderData } from '../../../services/guias'

export default {
  name: 'OrderPendingPaymentContent',
  data() {
    return {
      scrollPixels: 0,
      startEffect: 0,
      endEffect: 64,
      opacity: 0,
      blur: 0,

      intervalId: null
    }
  },
  mounted() {
    this.intervalId = setInterval(this.callApi, 4000)
    this.$watch('scrollPixels', pixeles => {
      let opacity = 0
      let blur = 0
      if (pixeles <= this.startEffect) {
        opacity = 0
        blur = this.blurMax
      } else if (pixeles >= this.endEffect) {
        opacity = 1
        blur = this.blurMin
      } else {
        opacity = (pixeles - this.startEffect) / (this.endEffect - this.startEffect)
        blur = 2 - opacity * 2
      }
      this.opacity = opacity
      this.blur = blur
    })
  },
  methods: {
    async callApi() {
      if (this.payment && this.payment.extraParameters && this.payment.extraParameters.BAR_CODE) {
        const orderData = await getOderData(this.payment.orderId)
        if (orderData.state === 'PENDIENTE') {
          clearInterval(this.intervalId)
          this.$store.dispatch("SCHARFFPOINT_FARE", 0)
        }
      }
    },
    copyText() {
      if (this.payment && this.payment.extraParameters && this.payment.extraParameters.BAR_CODE) {

        navigator.clipboard.writeText(this.payment.extraParameters.BAR_CODE)
          .then(() => {
            console.log('Text copied to clipboard');
            this.trackEvent('click_copiar_cip')
          })
          .catch((error) => {
            console.error('Could not copy text: ', error);
            this.trackEvent('click_copiar_cip_error')
          });

      } else {
        console.error('BAR_CODE is not available');
      }
    },
    handleScroll() {
      this.scrollPixels = this.$refs.nav.scrollTop
    },
    redirectHome() {
      sessionStorage.setItem("beenhere", "0");
      this.$store.dispatch("STYLE_HOME_DIV");
      this.$store.dispatch("HELPER_SET_CURRENT_NAV_SECTION", "servicio");
      window.location.href = process.env.VUE_APP_WEBURL;
    },
  },
  computed: {
    titleStyles() {
      return {
        opacity: this.opacity,
        // width: '125px',
        // filter: `blur(${this.blur * 2}px)`,
        //'text-align': 'center'
        // "background-color": this.bgColor,
        // height: `${this.height}px`
      };
    },
    lstDestination() {
      return this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
    },
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    contactOrigin() {
      return this.$store.getters.SCHARFFPOINT_ORIGIN_CONTACT;
    },
    productValue() {
      return this.$store.getters.SCHARFFPOINT_PRODUCT_VALUE;
    },
    lstDestinationProgrammed() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    originProgrammed() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    contactOriginProgrammed() {
      return this.$store.getters.PROGRAMMED_ORIGIN_CONTACT;
    },
    productValueProgrammed() {
      return this.$store.getters.PROGRAMME_PRODUCT_VALUE;
    },
    type() {
      return this.$store.getters.SCHARFFPOINT_ORDER_TYPE;
    },
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
  },
};
</script>

<style>
.title-icon {
  width: 28px;
  height: 28px;
  align-self: center;
}

.copy-icon {
  width: 20px;
  height: 20px;
  align-self: center;
}

.fix-padding-pending {
  margin: -0.6em;
}

.button-copy {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-content: center;
  padding: 12px 0px 0px 0px;
  cursor: pointer;
  width: fit-content;
}

.color-primary {
  /* Primary/500 */
  color: #FF565D !important;
}

.pending-content-body {
  background-color: #FFEFEF;
  display: flex;
  flex-direction: column;
}

.pending-nav-header {
  height: 64px;
  padding: 8px 20px;
  background-color: #FFEFEF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pending-nav-header-title {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-items: center;
  height: 24px;
  justify-self: center;
}

.home-button-nav {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  left: 0px;
}

.home-button-nav-right {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  right: 12px;
}

.box-title-pending {
  display: flex;
  flex-direction: row;
  padding: 0px 32px;
}

.content-pending {
  background-color: #FFFFFF;
  border-radius: 0;
  padding: 48px 16px 125px 16px;
  display: flex;
  flex-direction: column;
}

.content-pending-son {
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0px 0px;
}

.sub-title-waiting-for-pay {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #68002B;
  background-color: #FFEFEF;
  padding-bottom: 12px;
}

@media (min-width: 600px) {
  .content-pending {
    padding: 48px 32px 125px 32px;
  }
}

@media (min-width: 900px) {
  .content-pending-son {
    padding: 0px 0px !important;
  }

  .fix-padding-pending {
    margin: -0.6em;
    max-width: 440px;
  }

  .box-info {
    max-width: 376px;
  }
}

.title-pending {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #68002B;
  padding: 16px;
}

.description-payment {
  /* Text M/Regular */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */


  /* Almost black */

  color: #1A1A1A;
}

.box-payment-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card-payment-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #D0D0D0;
  border-radius: 16px;
}

.cip-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.card-payment-content-no-gap {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #D0D0D0;
  border-radius: 16px;
}

.text-s {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */


  /* Grey */

  color: #737373;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card-info-order-details {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #D0D0D0;
  border-radius: 16px;
}

.info-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text-l {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1A1A1A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-2xl {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1A1A1A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-m {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1A1A1A;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}

.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 2s infinite;
  content: '';
}

.color-brown {
  color: #68002B;
}

@keyframes dotty {
  0% { content: '' }

  25% { content: '.' }

  50% { content: '..' }

  75% { content: '...' }

  100% { content: '' }
}
</style>